.selective-view {
    width: 65%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    transform: translateY(-15px);
}

.selective-view-button-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 120px;
    min-width: 120px;
    height: 250px;
}

.selective-view-button {
    flex: 1;
    margin: 10px;
    width: 100px;
    height: 100px;
    border-radius: 200px;
    border: none;
    outline: none;
    background-color: #303C6C;
    box-shadow: 2px 2px 2px #7e8184;
    cursor: pointer;
}

.selective-view-panel-container {
    flex: 1;
    border-left: 5px solid #303C6C;
    border-radius: 8px
}

.selective-view-panel {
    position: absolute;
    display: inline-block;
    opacity: 0;
    transform: translateY(-200px);
    overflow-y: auto;
    overflow-X: hidden;
    height: 250px;
}

.selective-view-button-img {
    border-radius: 100%;
    background: #f0dee2;
    height: 88px;
    width: 88px;
    margin-left: 0px;
    margin-top: 4px;
}

.selective-view-panel-item {
    display: inline-block;
    max-width: 90px;
    transform: translateY(-25px);
    padding-left: 3vw;
    padding-top: 30px;
}

.selective-view-panel-item-img {
    height: 78px;
    width: 78px;
}

.selective-view-panel-item-label {
    color: #303C6C;
    font-weight: bold;
    margin-top: -5px;
    text-align: center;
    width: 78px;
}

.imageless {
    transform: translateY(-40px);
    border-radius: 100%;
}

.selective-view-panel-animation-enter {
    opacity: 0;
    transform: translateY(-200px);
}

.selective-view-panel-animation-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.selective-view-panel-animation-enter-done {
    opacity: 1;
    transform: translateY(0);
}

.selective-view-panel-animation-exit {
    opacity: 1;
    transform: translateY(0);
}

.selective-view-panel-animation-exit-active {
    opacity: 0;
    transform: translateY(200px);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.selective-view-panel-animation-exit-done {
    opacity: 0;
    transform: translateY(200px);
}

.selective-view-button-animation-enter {
    border-radius: 200px;
    margin-right: 10px;
    width: 100px;
}

.selective-view-button-animation-enter-active {
    margin-right: 0;
    border-radius: 200px 0 0 200px;
    width: 110px;
    box-shadow: none;
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.selective-view-button-animation-enter-done {
    margin-right: 0;
    width: 110px;
    border-radius: 200px 0 0 200px;
    box-shadow: none;
}

.selective-view-button-animation-exit {
    margin-right: 0;
    width: 110px;
    border-radius: 200px 0 0 200px;
    box-shadow: none;
}

.selective-view-button-animation-exit-active {
    width: 100px;
    margin-right: 10px;
    border-radius: 200px;
    box-shadow: 2px 2px 2px #7e8184;
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.selective-view-button-animation-exit-done {
    width: 100px;
    margin-right: 10px;
    border-radius: 200px;
    box-shadow: 2px 2px 2px #7e8184;
}

.selective-view-panel::-webkit-scrollbar {
    background-color:#fff;
    width:16px
}

/* background of the scrollbar except button or resizer */
.selective-view-panel::-webkit-scrollbar-track {
    background-color:#fff
}

/* scrollbar itself */
.selective-view-panel::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
.selective-view-panel::-webkit-scrollbar-button {display:none}
