.login-title {
    margin: 100px 0 0 145px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #242d51;
    font-size: 25px;
    opacity: 0.8;
}

.login-title-name {
    font-size: 35px;
    font-weight: bold;
    opacity: 1;
}

.login-label {
    color: #242d51;
    margin: 50px 0 0 145px;
}

.login-form {
    position: relative;
    padding: 10px 30px 30px 30px;
    margin: 0 115px;
    z-index: 10;
    display: flex;
    flex-wrap: nowrap;
}

.login-button {
    border: none;
    flex: 1;
    outline: 0;
    min-width: 50px;
    max-width: 50px;
    height: 37px;
    color: #9faad4;
    font-size: 20px;
    line-height: 19px;
    background-color: #242d51;
    transition: background-color 200ms;
}

.login-button:hover {
    background-color: #303c6c;
}

.login-input {
    border: none;
    margin-right: 5px;
    flex: 1;
    outline: 0;
    min-width: 250px;
    max-width: 250px;
    height: 35px;
    font-size: 20px;
    padding-left: 10px;
    background: #bac2e1;
    color: #242d51;
}

.login-input:focus {
    border-bottom: 2px solid #242d51;
    transition: all 100ms;
}

.login-form-animation {
    animation: shake 300ms;
}

@keyframes shake {
    0%   {transform: translateX(0)}
    20%  {transform: translateX(-10px)}
    40%  {transform: translateX(10px)}
    60%  {transform: translateX(-10px)}
    80%  {transform: translateX(10px)}
    100% {transform: translateX(0)}
  }