body {
    font-family: Source Sans Pro, Arial, Helvetica, sans-serif;
    margin: 0;
    background: white;
    overflow-x: hidden;
}

.app {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow-x: hidden;
}

.block-scroll {
    overflow-y: hidden;
}

.mouse-down-enter {
    transform: translate(-100%)
}
.mouse-down-enter-active {
    transform: translate(0);
    transition: transform 1s cubic-bezier(.31, .72, .22, .88);
}
.mouse-down-enter-done {
    transform: translate(0);
}

body::-webkit-scrollbar {
    background-color:#fff;
    width:16px
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
    background-color:#fff
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {display:none}
