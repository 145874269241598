.footer {
    position: absolute;
    vertical-align: bottom;
    width: 100%;
    height: 300px;
    background: #303C6C;
}

.footer-table {
    color: #bebebe;
    margin: 90px auto;
}

.footer-table tr {
    color: #a1d7de;
}

.footer-table td {
    padding: 5px 100px 5px 100px;
    color: #B4DFE5;
}

.footer-table td:hover {
    color: #EDF5E1;
    transition: all 200ms;
}
