.timeline {
    margin: 50px 10% 50px 20%;
}

.timeline-node {
    margin: 0 30px 0 30px;
    padding: 15px;
    border-left: 5px solid #303C6C;
}

.timeline-node-start {
    display: block;
    width: 30px;
    height: 30px;
    border: 5px solid #303C6C;
    border-radius: 30px;
    margin: -15px 0 0 -37px;
    background: #303C6C;
}

.timeline-node-end {
    margin: 0 30px 0 30px;
    border-left: 5px solid #303C6C;
}

.timeline-node-knot {
    display: block;
    width: 5px;
    height: 5px;
    border: 5px solid #303C6C;
    border-radius: 10px 10px 10px 10px;
    margin-left: -25px;
    background: white;
}

.timeline-node-knot-tag {
    display: inline-block;
    padding: 5px;
    color: white;
    font-weight: bold;
    margin: 0 0 -30px -140px;
    vertical-align: middle;
    background: #e86971;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
}

.timeline-node-knot-tag::after {
    content: "";
    width: 18px;
    height: 18px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    display: inline-block;
    position: absolute;
    top: 6px;
    right: -3px;
    z-index: -1;
    background-color: #e86971;
}

.timeline-node-leaf {
    display: inline-block;
    margin: -24px 0 0 10px;
    padding: 10px;
    box-shadow: 2px 2px 2px #7e8184;
    background-color: #f6f7f7;
    border: 1px solid #303C6C;
}

.timeline-node-leaf-knot {
    margin: 0 0 -16px -16px;
    display: block;
    width: 10px;
    height: 10px;
    border-width: 1px 0 0 1px;
    border-top: 1px solid #303C6C;
    border-left: 1px solid #303C6C;
    background: #f6f7f7;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.timeline-node-leaf-title {
    margin: 0 0 12px 5px;
    font-weight: bold;
}

.timeline-node-leaf-content {
    margin: 0 0 0 5px;
}

.timeline-node-leaf-animation-preset {
    opacity: 0;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
}

.timeline-node-leaf-animation-enter {
    opacity: 0;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
}

.timeline-node-leaf-animation-enter-active {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.timeline-node-leaf-animation-enter-done {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.timeline-node-knot-tag-animation-enter {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
}

.timeline-node-knot-tag-animation-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.timeline-node-knot-tag-animation-enter-done {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.selective-view {
    width: 65%;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
}

.selective-view-button-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    max-width: 120px;
    min-width: 120px;
    height: 250px;
}

.selective-view-button {
    flex: 1 1;
    margin: 10px;
    width: 100px;
    height: 100px;
    border-radius: 200px;
    border: none;
    outline: none;
    background-color: #303C6C;
    box-shadow: 2px 2px 2px #7e8184;
    cursor: pointer;
}

.selective-view-panel-container {
    flex: 1 1;
    border-left: 5px solid #303C6C;
    border-radius: 8px
}

.selective-view-panel {
    position: absolute;
    display: inline-block;
    opacity: 0;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
    overflow-y: auto;
    overflow-X: hidden;
    height: 250px;
}

.selective-view-button-img {
    border-radius: 100%;
    background: #f0dee2;
    height: 88px;
    width: 88px;
    margin-left: 0px;
    margin-top: 4px;
}

.selective-view-panel-item {
    display: inline-block;
    max-width: 90px;
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    padding-left: 3vw;
    padding-top: 30px;
}

.selective-view-panel-item-img {
    height: 78px;
    width: 78px;
}

.selective-view-panel-item-label {
    color: #303C6C;
    font-weight: bold;
    margin-top: -5px;
    text-align: center;
    width: 78px;
}

.imageless {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    border-radius: 100%;
}

.selective-view-panel-animation-enter {
    opacity: 0;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
}

.selective-view-panel-animation-enter-active {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.selective-view-panel-animation-enter-done {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.selective-view-panel-animation-exit {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.selective-view-panel-animation-exit-active {
    opacity: 0;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.selective-view-panel-animation-exit-done {
    opacity: 0;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
}

.selective-view-button-animation-enter {
    border-radius: 200px;
    margin-right: 10px;
    width: 100px;
}

.selective-view-button-animation-enter-active {
    margin-right: 0;
    border-radius: 200px 0 0 200px;
    width: 110px;
    box-shadow: none;
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.selective-view-button-animation-enter-done {
    margin-right: 0;
    width: 110px;
    border-radius: 200px 0 0 200px;
    box-shadow: none;
}

.selective-view-button-animation-exit {
    margin-right: 0;
    width: 110px;
    border-radius: 200px 0 0 200px;
    box-shadow: none;
}

.selective-view-button-animation-exit-active {
    width: 100px;
    margin-right: 10px;
    border-radius: 200px;
    box-shadow: 2px 2px 2px #7e8184;
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.selective-view-button-animation-exit-done {
    width: 100px;
    margin-right: 10px;
    border-radius: 200px;
    box-shadow: 2px 2px 2px #7e8184;
}

.selective-view-panel::-webkit-scrollbar {
    background-color:#fff;
    width:16px
}

/* background of the scrollbar except button or resizer */
.selective-view-panel::-webkit-scrollbar-track {
    background-color:#fff
}

/* scrollbar itself */
.selective-view-panel::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
.selective-view-panel::-webkit-scrollbar-button {display:none}

.footer {
    position: absolute;
    vertical-align: bottom;
    width: 100%;
    height: 300px;
    background: #303C6C;
}

.footer-table {
    color: #bebebe;
    margin: 90px auto;
}

.footer-table tr {
    color: #a1d7de;
}

.footer-table td {
    padding: 5px 100px 5px 100px;
    color: #B4DFE5;
}

.footer-table td:hover {
    color: #EDF5E1;
    transition: all 200ms;
}

.header {
    vertical-align: top;
    width: 100%;
    height: 350px;
    background: #303C6C;
    z-index: 5;
    box-shadow: 5px 5px 5px #0004;
}

.header-content {
    display: flex;
    padding: 50px 30% 50px 30%;
    align-items: center;
}

.header-title {
    flex: 1 1;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #D2FDFF;
    font-size: 35px;
    opacity: 0.8;
}

.header-title-name {
    font-size: 45px;
    font-weight: bold;
    opacity: 1;
}

.header-img {
    margin-left: 20px;
    flex: 1 1;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    border-radius: 100%;
}

.language-chart-container {
    margin: 50px auto 50px;
    width: 60%;
    height: 500px
}

.expandable-container {
    margin: auto;
    width: 700px;
}

.expandable-right{
    height: 200px;
    width: 300px;
    margin: 50px 50px 50px 0;
}

.expandable-left{
    height: 200px;
    width: 300px;
    margin: 50px 50px 50px 0;
}

.expandable-master {
    border-radius: 100%;
    width: 220px;
    height: 220px;
    background-color: #303C6C;
    z-index: 2;
    position: relative;
    -webkit-transform: translateX(75px);
            transform: translateX(75px);
}

.expandable-master-img {
    background: #f0dee2;
    border-radius: 100%;
    width: 188px;
    height: 188px;
    margin-top: 17px;
    margin-left: 15px;
}

.expandable-detail-right {
    border-radius: 0 25px 25px 0;
    height: 120px;
    -webkit-transform: translate(150px, -200px);
            transform: translate(150px, -200px);
    z-index: 1;
    padding: 30px 30px 30px 80px;
    position: relative;
    width: 0;
    background-color: #d9a9b2;
    box-shadow: 5px 5px 5px #0004;
}

.expandable-detail-left {
    border-radius: 25px 0 0 25px;
    height: 120px;
    -webkit-transform: translate(100px, -200px);
            transform: translate(100px, -200px);
    z-index: 1;
    padding: 30px 80px 30px 30px;
    position: relative;
    width: 0;
    background-color: #d9a9b2;
    box-shadow: 5px 5px 5px #0004;
}

.expandable-detail-content {
    opacity: 0;
    display: inline-block;
    padding-top: 45px;
    color: #303C6C;
    font-weight: bold;
}

.expandable-master-animation-left-enter {
    -webkit-transform: translateX(75px);
            transform: translateX(75px);
}

.expandable-master-animation-left-enter-active {
    -webkit-transform: translateX(490px);
            transform: translateX(490px);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.expandable-master-animation-left-enter-done {
    -webkit-transform: translateX(490px);
            transform: translateX(490px);
}

.expandable-master-animation-right-enter {
    -webkit-transform: translateX(75px);
            transform: translateX(75px);
}

.expandable-master-animation-right-enter-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.expandable-master-animation-right-enter-done {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.expandable-detail-animation-enter {
    width: 0;
}

.expandable-detail-animation-enter-active {
    width: 350px;
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.expandable-detail-animation-enter-done {
    width: 350px;
}

.expandable-detail-content-animation-enter {
    opacity: 0;
}

.expandable-detail-content-animation-enter-active {
    opacity: 1;
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.expandable-detail-content-animation-enter-done {
    opacity: 1;
}

.panel {
    width: 80%;
    background: #f0dee2;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 5px 5px 5px #0004;
    margin: -70px auto 110px;
    z-index: 10;
}

.panel-login {
    width: 600px;
    height: 400px;
    background: #9faad4;
    box-shadow: 5px 5px 5px #0004;
    margin: auto;
    z-index: 10;
}

.panel h2, .panel-login h2 {
    margin-left: 5%;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #303C6C;
}
.slide-wrapper {
    position: absolute;
    z-index: 11;
    width: 100vw;
    background: #6c303c;
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
}

.login-title {
    margin: 100px 0 0 145px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #242d51;
    font-size: 25px;
    opacity: 0.8;
}

.login-title-name {
    font-size: 35px;
    font-weight: bold;
    opacity: 1;
}

.login-label {
    color: #242d51;
    margin: 50px 0 0 145px;
}

.login-form {
    position: relative;
    padding: 10px 30px 30px 30px;
    margin: 0 115px;
    z-index: 10;
    display: flex;
    flex-wrap: nowrap;
}

.login-button {
    border: none;
    flex: 1 1;
    outline: 0;
    min-width: 50px;
    max-width: 50px;
    height: 37px;
    color: #9faad4;
    font-size: 20px;
    line-height: 19px;
    background-color: #242d51;
    transition: background-color 200ms;
}

.login-button:hover {
    background-color: #303c6c;
}

.login-input {
    border: none;
    margin-right: 5px;
    flex: 1 1;
    outline: 0;
    min-width: 250px;
    max-width: 250px;
    height: 35px;
    font-size: 20px;
    padding-left: 10px;
    background: #bac2e1;
    color: #242d51;
}

.login-input:focus {
    border-bottom: 2px solid #242d51;
    transition: all 100ms;
}

.login-form-animation {
    -webkit-animation: shake 300ms;
            animation: shake 300ms;
}

@-webkit-keyframes shake {
    0%   {-webkit-transform: translateX(0);transform: translateX(0)}
    20%  {-webkit-transform: translateX(-10px);transform: translateX(-10px)}
    40%  {-webkit-transform: translateX(10px);transform: translateX(10px)}
    60%  {-webkit-transform: translateX(-10px);transform: translateX(-10px)}
    80%  {-webkit-transform: translateX(10px);transform: translateX(10px)}
    100% {-webkit-transform: translateX(0);transform: translateX(0)}
  }

@keyframes shake {
    0%   {-webkit-transform: translateX(0);transform: translateX(0)}
    20%  {-webkit-transform: translateX(-10px);transform: translateX(-10px)}
    40%  {-webkit-transform: translateX(10px);transform: translateX(10px)}
    60%  {-webkit-transform: translateX(-10px);transform: translateX(-10px)}
    80%  {-webkit-transform: translateX(10px);transform: translateX(10px)}
    100% {-webkit-transform: translateX(0);transform: translateX(0)}
  }
.background-layer-1 {
    position: fixed;
    z-index: 8;
    width: 150%;
    height: 20vh;
    background-color: #242d51;
    -webkit-transform: translateX(-50px) rotate(45deg);
            transform: translateX(-50px) rotate(45deg);
}
.background-layer-2 {
    position: fixed;
    z-index: 7;
    width: 150%;
    height: 40vh;
    background-color: #2a345e;
    -webkit-transform: translateX(-50px) rotate(45deg);
            transform: translateX(-50px) rotate(45deg);
}
.background-layer-3 {
    position: fixed;
    z-index: 6;
    width: 150%;
    height: 60vh;
    background-color: #303c6c;
    -webkit-transform: translateX(-50px) rotate(45deg);
            transform: translateX(-50px) rotate(45deg);
}
.background-layer-4 {
    position: fixed;
    z-index: 5;
    width: 150%;
    height: 80vh;
    background-color: #36447a;
    -webkit-transform: translateX(-50px) rotate(45deg);
            transform: translateX(-50px) rotate(45deg);
}
.background-layer-5 {
    position: fixed;
    z-index: 4;
    width: 150%;
    height: 100vh;
    background-color: #3c4b87;
    -webkit-transform: translateX(-50px) rotate(45deg);
            transform: translateX(-50px) rotate(45deg);
}
.background-layer-6 {
    position: fixed;
    z-index: 3;
    width: 150%;
    height: 120vh;
    background-color: #425395;
    -webkit-transform: translateX(-50px) rotate(45deg);
            transform: translateX(-50px) rotate(45deg);
}
.background-layer-7 {
    position: fixed;
    z-index: 2;
    width: 150%;
    height: 140vh;
    background-color: #485aa2;
    -webkit-transform: translateX(-50px) rotate(45deg);
            transform: translateX(-50px) rotate(45deg);
}
.background-layer-8 {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background-color: #4e62b0;
}
body {
    font-family: Source Sans Pro, Arial, Helvetica, sans-serif;
    margin: 0;
    background: white;
    overflow-x: hidden;
}

.app {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow-x: hidden;
}

.block-scroll {
    overflow-y: hidden;
}

.mouse-down-enter {
    -webkit-transform: translate(-100%);
            transform: translate(-100%)
}
.mouse-down-enter-active {
    -webkit-transform: translate(0);
            transform: translate(0);
    transition: -webkit-transform 1s cubic-bezier(.31, .72, .22, .88);
    transition: transform 1s cubic-bezier(.31, .72, .22, .88);
    transition: transform 1s cubic-bezier(.31, .72, .22, .88), -webkit-transform 1s cubic-bezier(.31, .72, .22, .88);
}
.mouse-down-enter-done {
    -webkit-transform: translate(0);
            transform: translate(0);
}

body::-webkit-scrollbar {
    background-color:#fff;
    width:16px
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
    background-color:#fff
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {display:none}

