.expandable-container {
    margin: auto;
    width: 700px;
}

.expandable-right{
    height: 200px;
    width: 300px;
    margin: 50px 50px 50px 0;
}

.expandable-left{
    height: 200px;
    width: 300px;
    margin: 50px 50px 50px 0;
}

.expandable-master {
    border-radius: 100%;
    width: 220px;
    height: 220px;
    background-color: #303C6C;
    z-index: 2;
    position: relative;
    transform: translateX(75px);
}

.expandable-master-img {
    background: #f0dee2;
    border-radius: 100%;
    width: 188px;
    height: 188px;
    margin-top: 17px;
    margin-left: 15px;
}

.expandable-detail-right {
    border-radius: 0 25px 25px 0;
    height: 120px;
    transform: translate(150px, -200px);
    z-index: 1;
    padding: 30px 30px 30px 80px;
    position: relative;
    width: 0;
    background-color: #d9a9b2;
    box-shadow: 5px 5px 5px #0004;
}

.expandable-detail-left {
    border-radius: 25px 0 0 25px;
    height: 120px;
    transform: translate(100px, -200px);
    z-index: 1;
    padding: 30px 80px 30px 30px;
    position: relative;
    width: 0;
    background-color: #d9a9b2;
    box-shadow: 5px 5px 5px #0004;
}

.expandable-detail-content {
    opacity: 0;
    display: inline-block;
    padding-top: 45px;
    color: #303C6C;
    font-weight: bold;
}

.expandable-master-animation-left-enter {
    transform: translateX(75px);
}

.expandable-master-animation-left-enter-active {
    transform: translateX(490px);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.expandable-master-animation-left-enter-done {
    transform: translateX(490px);
}

.expandable-master-animation-right-enter {
    transform: translateX(75px);
}

.expandable-master-animation-right-enter-active {
    transform: translateX(0);
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.expandable-master-animation-right-enter-done {
    transform: translateX(0);
}

.expandable-detail-animation-enter {
    width: 0;
}

.expandable-detail-animation-enter-active {
    width: 350px;
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.expandable-detail-animation-enter-done {
    width: 350px;
}

.expandable-detail-content-animation-enter {
    opacity: 0;
}

.expandable-detail-content-animation-enter-active {
    opacity: 1;
    transition: all 400ms cubic-bezier(.31, .72, .22, .88);
}

.expandable-detail-content-animation-enter-done {
    opacity: 1;
}
