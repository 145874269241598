.header {
    vertical-align: top;
    width: 100%;
    height: 350px;
    background: #303C6C;
    z-index: 5;
    box-shadow: 5px 5px 5px #0004;
}

.header-content {
    display: flex;
    padding: 50px 30% 50px 30%;
    align-items: center;
}

.header-title {
    flex: 1;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #D2FDFF;
    font-size: 35px;
    opacity: 0.8;
}

.header-title-name {
    font-size: 45px;
    font-weight: bold;
    opacity: 1;
}

.header-img {
    margin-left: 20px;
    flex: 1;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    border-radius: 100%;
}
